import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Container from "../components/Container";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const Gorgias = () => {
	const pageTitle = "Gorgias Integration";

	return (
		<Container>
			<HelmetProvider>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
			</HelmetProvider>
			<Stack spacing={{ xs: 4, sm: 8 }}>
				<Stack spacing={2}>
					<Typography
						variant="h3"
						gutterBottom
						sx={{
							fontWeight: 700,
						}}
					>
						How can I integrate SavedBy with Gorgias?
					</Typography>
					<Typography>
						By integrating SavedBy with Gorgias, your customer service team will be able to quickly submit and view the status of customer claims directly from Gorgias tickets and customer profiles.
					</Typography>
					<Typography fontWeight={700} variant={"h4"}>
						Installation
					</Typography>
					<Typography>
						<ol>
							<li>
								<b>Install SavedBy on Shopify </b>
								Ensure that SavedBy is installed on your Shopify store. You can install it from the{" "}
								<Button LinkComponent={Link} to="https://apps.shopify.com/savedby">
									Shopify App Store.
								</Button>
								SavedBy is free for all Gorgias users, so there’s no additional cost!
							</li>

							<li>
								<b>Install SavedBy in Gorgias </b>
								<ul>
									<li>
										Install the SavedBy app from the
										<Button LinkComponent={Link} to="https://savedby.gorgias.com/app/settings/integrations/app/6618319566be1309e32d99c2">
											Gorgias App Store.
										</Button>
									</li>
									<li>
										Alternatively, you can initiate the integration from the
										<Button LinkComponent={Link} to="https://merchant.savedby.io/integrations/gorgias">
											SavedBy Merchant Portal
										</Button>
										. Make sure you&apos;re logged in with the required permissions (specifically, the integrations permission), and have your Gorgias account name available.
									</li>
								</ul>
							</li>
						</ol>
					</Typography>
					<Typography fontWeight={700} variant={"h4"}>
						Using the SavedBy Widget in Gorgias
					</Typography>
					<Typography>Once the integration is complete, you&apos;ll have access to the SavedBy widget in Gorgias, which allows you to submit claims quickly and check claim statuses.</Typography>
					<Typography>
						The widget will appear in two locations:
						<ul>
							<li>
								<b>Within Tickets:</b> View the customer&apos;s last five orders and see if they&apos;re protected by SavedBy. You can file a claim directly from the ticket. In
							</li>
							<li>
								<b>Customer Profiles:</b> Similarly, you can view recent orders and submit claims on behalf of the customer.
							</li>
						</ul>
					</Typography>
					<Typography>After filing a claim, you’ll be able to track its status through the widget. Any updates to the claim will also be reflected here.</Typography>
					<Typography fontWeight={700} variant={"h4"}>
						FAQs
					</Typography>
					<ul>
						<li>
							<b>I just integrated SavedBy, but don’t see the widget in a ticket. What should I do?</b>
							<Typography ml={2}>
								It’s normal not to see the widget right away after a recent install. Gorgias widgets refresh when a ticket is updated. To force an update, you can simply make a small change to the
								ticket, such as adding and removing a tag.
							</Typography>
						</li>

						<li>
							<b>My Gorgias account is connected to multiple Shopify stores. How does SavedBy know which store an order belongs to?</b>
							<Typography ml={2}>SavedBy identifies the correct Shopify store by the order ID. As long as the order ID is correct, we’ll associate the claim with the right store.</Typography>
						</li>

						<li>
							<b>How do I disconnect the SavedBy integration from Gorgias?</b>
							<Typography ml={2}>
								To remove the integration, go to the Gorgias integration section in the SavedBy Merchant Portal. In the top-right menu, you’ll find an option to disconnect the integration. This will
								remove the SavedBy widget from tickets and customer profiles, as well as the HTTP integration.
							</Typography>
						</li>

						<li>
							<b>I submitted a claim but need to edit the information. What can I do?</b>
							<Typography ml={2}>No problem! You can contact your Merchant Success Manager or email our support team at support@savedby.io, and we’ll make the necessary adjustments.</Typography>
						</li>
					</ul>

					<Typography fontWeight={700} variant={"h4"}>
						Need Additional Help?
					</Typography>
					<Typography>For further assistance, reach out to your Merchant Success Manager or contact our support team at support@savedby.io. We’re here to help!</Typography>
				</Stack>
			</Stack>
		</Container>
	);
};

export default Gorgias;
